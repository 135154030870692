import React from "react";
import { Routes, Route, ScrollRestoration } from "react-router-dom";
import "./sass/app.scss";
import HomePage from "./pages/HomePage";
import Footer from "./templates/Footer";
import Header from "./templates/Header";
import ReportPage from "./pages/ReportPage";
import MemberPage from "./pages/MemberPage";
import RulePage from "./pages/RulePage";
import LaosPage from "./pages/LaosPage";
import TenYearsPage from "./pages/TenYearsPage";
import ArticlePage from "./pages/ArticlePage";
import AssociationPage from "./pages/AssociationPage";
import NewsPage from "./pages/news";
import Article1 from "./pages/news/article/Article1";
import PlanPage from "./pages/PlanPage";
import OfficerPage from "./pages/OfficerPage";
import Article2 from "./pages/news/article/Article2";
import Error404 from "./pages/error/Error404";
import Article3 from "./pages/news/article/Article3";
import Article4 from "./pages/news/article/Article4";
import Article5 from "./pages/news/article/Article5";
import Article6 from "./pages/news/article/Article6";
import Article7 from "./pages/news/article/Article7";
import Article8 from "./pages/news/article/Article8";
const App: React.FC = () => {
    React.useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                sessionStorage.setItem("userView", "sp");
                sessionStorage.setItem("isShow", "true");
            } else {
                sessionStorage.setItem("userView", "pc");
                sessionStorage.setItem("isShow", "true");
            }
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <>
            <div className="bg-background h-auto min-h-screen flex flex-col overflow-hidden font-meiryo">
                <Header />
                {/* SideMenuが左2割を、それ以外が8割を占める */}
                <div className="h-fit py-8 w-full flex flex-col grow items-center">
                    <div className="h-full w-full flex flex-col md:flex-row justify-center flex-nowrap">
                        <div className="h-full w-full">
                            <Routes>
                                <Route path="/" element={<HomePage />} />
                                <Route path="/news" element={<NewsPage />} />
                                <Route
                                    path="/news/article1"
                                    element={<Article1 />}
                                />{" "}
                                <Route
                                    path="/news/article2"
                                    element={<Article2 />}
                                />
                                <Route
                                    path="/news/article3"
                                    element={<Article3 />}
                                />
                                <Route
                                    path="/news/article4"
                                    element={<Article4 />}
                                />
                                <Route
                                    path="/news/article5"
                                    element={<Article5 />}
                                />
                                <Route
                                    path="/news/article6"
                                    element={<Article6 />}
                                />
                                <Route
                                    path="/news/article7"
                                    element={<Article7 />}
                                />
                                <Route
                                    path="/news/article8"
                                    element={<Article8/>}
                                />
                                <Route path="/plan" element={<PlanPage />} />
                                <Route
                                    path="/report"
                                    element={<ReportPage />}
                                />
                                <Route
                                    path="/officer"
                                    element={<OfficerPage />}
                                />
                                <Route
                                    path="/member"
                                    element={<MemberPage />}
                                />
                                <Route path="/rule" element={<RulePage />} />
                                <Route path="/laos" element={<LaosPage />} />
                                <Route
                                    path="/article"
                                    element={<ArticlePage />}
                                />
                                <Route
                                    path="/ten-years"
                                    element={<TenYearsPage />}
                                />
                                <Route
                                    path="association"
                                    element={<AssociationPage />}
                                />
                                <Route path="*" element={<Error404 />} />
                            </Routes>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
            <ScrollRestoration />
        </>
    );
};

export default App;
