import React from "react";
import { Link } from "react-router-dom";
const NewsPage: React.FC = () => {
    const news = [
                {
            title: "ラオスニュースNo.28が発行されました！",
            detail: "この度、当協会は令和7年3月に最新の会報誌を発行いたしました。",
            date: "2025/3/15",
            link: "/news/article8",
        },
        {
            title: "ラオス訪問団2025　募集案内",
            detail: "令和7年2月　ラオス・ベトナムに訪問団を派遣します。",
            date: "2024/10/30",
            link: "/news/article7",
        },
        {
            title: "R6年度　理事会・総会・交流会開催通知",
            detail: "令和6年度の理事会・総会及び会員交流会の情報をお知らせします。",
            date: "2024/6/13",
            link: "/news/article6",
        },
        {
            title: "ラオスニュースNo.27が発行されました！",
            detail: "この度、当協会は令和6年4月に最新の会報誌を発行いたしました。",
            date: "2024/4/24",
            link: "/news/article5",
        },
        {
            title: "ラオスニュースNo.26が発行されました！",
            detail: "この度、当協会は令和5年8月に最新の会報誌を発行いたしました。",
            date: "2023/08/29",
            link: "/news/article4",
        },
        {
            title: "微笑みの国・ラオスセミナーについて。",
            detail: "福岡・ラオス友好協会４年ぶりのラオスセミナーと交流会を開催いたします",
            date: "2023/07/03",
            link: "/news/article3",
        },
        {
            title: "理事会・総会及び会員交流会の開催について",
            detail: "令和5度の理事会・総会及び会員交流会の情報をお知らせします。",
            date: "2023/05/21",
            link: "/news/article2",
        },
        {
            title: "ラオスニュースNo.25が発行されました！",
            detail: "この度、当協会は令和5年2月に最新の会報誌を発行いたしました。",
            date: "2023/02/10",
            link: "/news/article1",
        },
    ];
    return (
        // ニュース一覧を表示
        //  ページのタイトルは左上に表示
        // 日時、タイトルを表示
        // linkで囲って、クリックすると詳細ページに飛ぶ
        <div className="base-margin">
            <h1 className="text-4xl font-bold text-start my-8">お知らせ</h1>
            <div className="flex flex-col justify-center items-center">
                <div className="m-4 flex flex-col w-full">
                    {news.map((item) => (
                        <Link
                            to={item.link}
                            className="mx-2 border-b py-6 flex flex-col md:flex-row md:gap-32 items-center hover:bg-gray-100"
                        >
                            <p className="md:ml-16 w-full md:w-fit md:text-center text-md">
                                {item.date}
                            </p>
                            <div className="flex flex-col gap-4">
                                <p className="text-xl font-bold">
                                    {item.title}
                                </p>
                                <p className="text-lg">{item.detail}</p>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default NewsPage;
