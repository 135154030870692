import React from "react";
import { Link } from "react-router-dom";
const Article5: React.FC = () => {
    return (
        // ニュース一覧を表示
        //  ページのタイトルは左上に表示
        // 日時、タイトルを表示
        // linkで囲って、クリックすると詳細ページに飛ぶ
        <div className="base-margin">
            <div className="my-8">
                <h1 className="text-2xl md:text-4xl font-bold text-start">
                    ラオスニュースNo.27が発行されました！
                </h1>
                <p className="text-sx text-start text-gray-500">2024/04/24</p>
            </div>
            <div className="mx-4 md:mx-16 text-base">
                <p>
                    この度、当協会は令和6年4月に最新の会報誌を発行いたしました。
                    <br />
                    本誌では、ラオスの近況や日本で行われたイベントについてなど、盛りだくさんの内容をお届けしております。
                    <br />
                    ぜひ、当協会のwebページにアクセスしていただき、最新の会報誌をご覧ください。
                    <br />
                    当協会の取り組みや成果、さらにはこれからの展望についても詳しくご紹介しております。
                    <br />
                    皆様のご支援とご愛顧に感謝申し上げながら、皆様のご期待にお応えするべく、日々努力を重ねております。
                    <br />
                    今後とも、当協会の活動にご注目ください。
                    <br />
                    <br />
                    福岡ラオス友好協会
                </p>
            </div>
            <div className="mt-8 flex justify-center">
                <Link
                    to={"/news"}
                    className="text-lg w-fit text-center py-2 border-b hover:bg-gray-100"
                >
                    <p>一覧に戻る</p>
                </Link>
            </div>
        </div>
    );
};

export default Article5;
